export class Constants {
  public static ApiUrl = process.env.BASE_API_URL;
  public static IsDev = process.env.NODE_ENV !== 'production';
  public static ReconnectionAttempts = 3;
  public static Validation = {
    EmailRegEx: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
    PhoneRegEx: /[0-9]{10}/,
  };
  public static QR = {
    PixelsPerModule: 6,
  };
  public static MaxPlayersSelected = 5;
  public static RedirectTimeout = 5000;
  public static OfflineTimeout = 20000;
  public static LookAtTimeout = 50000;
  public static PhotoCompressQuality = 0.95;
  public static FlashTimeMs = 500;
  public static PauseAtTimeSeconds = 7.1;
  public static VideoLoadedTimeSeconds = 10.9; /* this might be same as PauseAtTimeSeconds  since we can only load first X seconds */
  public static CanvasHeight = 1920;
  public static CanvasWidth = 1080;
  public static CameraWidth = 1920;
  public static CameraHeight = 1080;
  public static CropHeight = 1320;
  public static CropBottomMargin = 85 + 118;
  public static DelayBeforeLeaveCameraScreen = 2300;
  public static DelayBeforeLeavePlayersScreen = 700;
  public static ATTStaduimLocation = { lat: 32.747309, lon: -97.094489 };
  public static DistanceToStadiumKm = 321.87; //200mi
  public static IsGeoCheckEnabled = false;
  public static AspectRatio = 16 / 9;
  public static FrameRate = 30;
}

export enum Language {
  EN = 'en',
  ES = 'es',
}

export enum StorageKey {
  ContactInfo = 'contactInfo',
  TOSAgreed = 'tosAgreed',
  OptInAccepted = 'optIn',
  FinalImage = 'finalImage',
  Submit = 'submit',
  CameraType = 'cameraType',
  Lang = 'lang',
  LocationCheckCache = 'loc-validity-result',
  Config = 'config',
}

export enum StringKey {
  Back,
  ByCheckingTheFollowing,
  CameraError,
  CapturePhoto,
  CheckEmailOrText,
  Continue,
  ControlScreen,
  DesktopAlert,
  DownloadYourPhoto,
  ShareYourPhoto,
  Email,
  EmailPlaceholder,
  English,
  EnterEmail,
  EnterInfo,
  EnterMobile,
  EnterYourInfo,
  GetStarted,
  GetReady,
  HoldQR,
  Instructions,
  LevelPhone,
  LevelPhoneToBegin,
  LookAtBigScreen,
  OptIn,
  Reset,
  OpenInChrome,
  OpenInSafari,
  RotateTitle,
  RotateSubtitle,
  SelectCamera,
  SeflieMode,
  SeflieModeDescription,
  SkipSharing,
  Spanish,
  Start,
  Step,
  Submit,
  TagOnSocial,
  TakeAnotherPhoto,
  Text,
  Timeout,
  Thanks,
  ToGetPhoto,
  TOS,
  TryAgain,
  TurnOnGPS,
  YouAreOutside,
  Welcome,
  EmailPopup,
  PhonePopup,
  CheckboxPopup,
  SharingPopup,
  ShareFacebook,
  ShareTwitter,
  RearCamera,
  RearCameraDescription,
  RequiredText,
  WeBringingTheStadiumExperience,
}

export enum ImageKey {
  Footer = 'footer.png',
}

export enum ShareType {
  Phone = 'phone',
  Email = 'email',
  NoShare = 'noshare',
}

export enum Mode {
  Offline = 'offline',
  Online = 'online',
}

export enum CameraType {
  Rear = 'environment',
  Front = 'user',
}
