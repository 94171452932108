import * as Branding from '../../branding/branding.json';
import { StorageKey } from '../constants';
import { PhotoHeader } from '../images';
import { Player, PlayerPosition, PlayerVideo, VideoParts } from '../models';
import { ConfigStore } from '../models/config';
import { CnstM, SrvM } from '../modules';
import { MobileDeviceType } from './deviceService';
import { ResolvablePromise, Utils } from './utils';

interface IPhotoData {
  position: PlayerPosition;
  image: HTMLImageElement;
}

export class PreloadService {
  static deviceType: MobileDeviceType;
  public static LoadedPlayerPhotos: IPhotoData[] = [];
  public static PhotoHeader: HTMLImageElement;
  public static LoadedCameraVideos: HTMLVideoElement[];

  public static CameraPreload: ResolvablePromise<any> =
    Utils.newResolvablePromise();
  public static CameraPreloadComplete = false;

  public static MenuPreload: ResolvablePromise<any> =
    Utils.newResolvablePromise();
  public static MenuPreloadComplete = false;

  public static PlayerMenuVideos: PlayerVideo[] = Branding.team.players.map(
    (p) => {
      return { id: p.id, name: p.firstName } as PlayerVideo;
    }
  );

  public static Config = new ConfigStore(
    JSON.parse(localStorage.getItem(StorageKey.Config))
  );

  public static async startPreloadForCamera(players: Player[]) {
    this.CameraPreloadComplete = false;
    this.CameraPreload.then(() => {
      this.CameraPreloadComplete = true;
      SrvM.Logger.log(`Assets for camera page are preloaded now`);
    });
    this.deviceType = SrvM.DeviceService.detectDevice().deviceCategory;
    await this.preloadCameraVideos(players);
    await this.preloadCameraPhotos(players);
    await this.preparePhotoHeader();
    this.CameraPreload.resolve();
  }

  public static async startPreloadForMenu(onIntroLoadedCallback: () => void) {
    this.MenuPreload.then(() => {
      SrvM.Logger.log(`Menu preload complete`);
    });
    await this.prepareIntroPartVideos();
    SrvM.Logger.log(`intro videos preloaded`);
    await this.prepareLoopPartVideos();
    SrvM.Logger.log(`loop videos preloaded`);
    await this.prepareOutroPartVideos();
    SrvM.Logger.log(`outro videos preloaded`);
    this.MenuPreloadComplete = true;
    if (onIntroLoadedCallback) {
      onIntroLoadedCallback();
    }
    this.MenuPreload.resolve();
  }

  public static cleanupMenuResources() {
    this.PlayerMenuVideos.forEach((pv) => {
      [pv.intro, pv.loop, pv.outro].forEach((videoElement) => {
        if (videoElement) {
          videoElement.pause();
          videoElement.removeAttribute('src');
          if (videoElement.firstChild) {
            videoElement.removeChild(videoElement.firstChild);
          }
          if (videoElement.firstChild) {
            videoElement.removeChild(videoElement.firstChild);
          }
          videoElement.load();

          if (videoElement.parentElement) {
            videoElement.parentElement.removeChild(videoElement);
          }
        }
      });
      pv.outro = pv.intro = pv.loop = null;
    });
  }

  static async preloadCameraVideos(players: Player[]): Promise<any> {
    const playersPromises = players.map((p) =>
      this.preparePlayerCameraVideo(p)
    );
    const videos = await Promise.all<HTMLVideoElement>(playersPromises);
    this.LoadedCameraVideos = videos;
  }

  static async preloadCameraPhotos(players: Player[]): Promise<IPhotoData[]> {
    const playersPromises = players.map((p) =>
      this.preparePlayerCameraPhoto(p)
    );
    const playersPhotos = await Promise.all(playersPromises);
    this.LoadedPlayerPhotos = playersPhotos;
    return playersPhotos;
  }

  static async preparePhotoHeader(): Promise<any> {
    return new Promise((resolve) => {
      this.PhotoHeader = new Image(CnstM.Constants.CanvasWidth, 177);
      this.PhotoHeader.onload = () => {
        resolve(null);
      };
      this.PhotoHeader.src = PhotoHeader;
    });
  }

  static async preparePlayerCameraPhoto(player: Player): Promise<IPhotoData> {
    const startTime = new Date();
    const playerName = player.name;
    const src = `/players/${playerName}/${player.position}.png`;
    return new Promise((resolve, reject) => {
      const image = new Image(
        CnstM.Constants.CanvasWidth,
        CnstM.Constants.CanvasHeight
      );
      image.width = CnstM.Constants.CanvasWidth;
      image.height = CnstM.Constants.CanvasHeight;
      image.onload = () => {
        const endTime = new Date();
        SrvM.Logger.log(
          'preparePlayerPhoto',
          `${endTime.getTime() - startTime.getTime()}ms`
        );
        resolve({ image: image, position: player.position });
      };
      image.onerror = () => {
        SrvM.Logger.error('could not load image', src);
        reject();
      };
      image.src = src;
    });
  }

  static preparePlayerCameraVideo(player: Player): Promise<HTMLVideoElement> {
    const playerKey = player.key || player.name;
    const configPlayer = this.Config.players.find((p) => p.id === player.id);

    return new Promise((resolve) => {
      const startTime = new Date();
      const videoPlayer = PreloadService.createNewVideoElement(
        `video${player.position}`
      );
      videoPlayer.classList.add('video');
      videoPlayer.ondurationchange = () => {
        const duration = videoPlayer.duration;
        if (duration > CnstM.Constants.VideoLoadedTimeSeconds) {
          videoPlayer.ondurationchange = null;
          const endTime = new Date();
          SrvM.Logger.log(
            `preparePlayerVideo finished in ${
              endTime.getTime() - startTime.getTime()
            }ms for video ${videoPlayer.currentSrc}`
          );
          resolve(videoPlayer);
        }
      };

      Branding.video.types.forEach((t) => {
        const configVideo =
          !!configPlayer &&
          configPlayer.videos.find((v) => v.key === player.position);
        const src =
          (!!configVideo && configVideo.url) ||
          `${Branding.video.host}${Branding.video.rootUrl}/Player-${playerKey}/${player.position}${t.resolution}${t.extension}`;
        this.createVideoSource(videoPlayer, src, t.type);
      });
      videoPlayer.load();
    });
  }

  private static preparePlayerVideoPart(
    player: PlayerVideo,
    videoPart: VideoParts
  ): Promise<any> {
    const playerKey = player.key || player.name;
    const configPlayer = this.Config.players.find((p) => p.id === player.id);

    return new Promise((resolve) => {
      const videoPlayer = PreloadService.createNewVideoElement(
        `${player.name}${videoPart}`
      );
      videoPlayer.ondurationchange = () => {
        const duration = videoPlayer.duration;
        if (duration > 0) {
          videoPlayer.ondurationchange = null;
          switch (videoPart) {
            case VideoParts.Intro:
              player.intro = videoPlayer;
              break;
            case VideoParts.Loop:
              videoPlayer.setAttribute('loop', 'loop');
              player.loop = videoPlayer;
              break;
            case VideoParts.Outro:
              videoPlayer.playbackRate = 1.25;
              player.outro = videoPlayer;
              break;
          }
          resolve(null);
        }
      };

      Branding.video.types.forEach((t) => {
        const configVideo =
          !!configPlayer &&
          configPlayer.videos.find((v) => v.key === videoPart);
        const src =
          (!!configVideo && configVideo.url) ||
          `${Branding.video.host}${Branding.video.rootUrl}/Player-${playerKey}/PeekIn-${videoPart}${t.resolution}${t.extension}`;
        this.createVideoSource(videoPlayer, src, t.type);
      });

      const videos = document.getElementById('playersVideos');
      videos.appendChild(videoPlayer);
      videoPlayer.load();
    });
  }

  private static prepareIntroPartVideos(): Promise<any> {
    const playersPromises = this.PlayerMenuVideos.map((player) =>
      this.preparePlayerVideoPart(player, VideoParts.Intro)
    );
    return Promise.all(playersPromises);
  }

  private static prepareLoopPartVideos(): Promise<any> {
    const playersPromises = this.PlayerMenuVideos.map((player) =>
      this.preparePlayerVideoPart(player, VideoParts.Loop)
    );
    return Promise.all(playersPromises);
  }

  private static prepareOutroPartVideos(): Promise<any> {
    const playersPromises = this.PlayerMenuVideos.map((player) =>
      this.preparePlayerVideoPart(player, VideoParts.Outro)
    );
    return Promise.all(playersPromises);
  }

  private static createNewVideoElement(id: string) {
    const video = document.createElement('video') as HTMLVideoElement;
    video.id = id;
    // video.setAttribute('preload', 'auto');
    video.setAttribute('muted', '');
    video.setAttribute('playsInline', '');
    return video;
  }

  private static createVideoSource(
    video: HTMLVideoElement,
    src: string,
    type: string
  ) {
    const videoSource = document.createElement('source') as HTMLSourceElement;
    videoSource.src = src;
    videoSource.type = type;
    video.appendChild(videoSource);
    return videoSource;
  }
}
