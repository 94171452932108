import './header.scss';

import { h } from 'preact';

import { ImgM } from '../modules';

const handleHomeClick = () => {
  window.location.replace('/');
};

export const Header = () => {
  return (
    <header className="header-container">
      <div onClick={handleHomeClick} className="header-data-container align-cc">
        <img className="logo" src={ImgM.Logo} />
      </div>
      <div class="desktop-alert-container">
        <div class="desktop-alert-title"></div>
      </div>
      <div class="desktop-loader-container">
        <div class="loader"></div>
      </div>
    </header>
  );
};
