import './checkbox.scss';

import { h } from 'preact';
import { useState } from 'preact/hooks';

import { CnstM, SrvM } from '../modules';

interface IProps {
  name?: string;
  label?: string;
  checked?: boolean;
  required?: boolean;

  onChange?: (e: h.JSX.TargetedEvent<HTMLInputElement, Event>) => void;
}

export const Checkbox = (props: IProps) => {
  const [checked, setChecked] = useState(props.checked);
  const handleChange = (e: h.JSX.TargetedEvent<HTMLInputElement, Event>) => {
    setChecked(!checked);
    if (props.onChange) props.onChange(e);
  };

  return (
    <label class={`checkbox ${checked ? 'checkbox-checked' : ''}`}>
      <input
        type="checkbox"
        name={props.name}
        checked={checked}
        onChange={handleChange}
      />
      {props.label && (
        <span class="checkbox-label">
          {props.required ? <b>* </b> : ''}
          {props.label}{' '}
          {props.required ? (
            <b> {SrvM.i18n.txt(CnstM.StringKey.RequiredText)}</b>
          ) : (
            ''
          )}
        </span>
      )}
    </label>
  );
};
