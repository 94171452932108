export class Utils {
  public static async delay(delayMs: number): Promise<any> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(null), delayMs);
    });
  }

  public static newResolvablePromise<T>(): ResolvablePromise<T> {
    let res, rej;

    const promise = new Promise<T>((resolve, reject) => {
      res = resolve;
      rej = reject;
    }) as any as ResolvablePromise<T>;

    promise.resolve = res;
    promise.reject = rej;
    return promise;
  }
}

export interface ResolvablePromise<T> extends Promise<T> {
  resolve();
  reject();
}
