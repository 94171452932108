export class PlayerVideoConfigStore {
  public key: string;
  public url: string;

  constructor(src?: Partial<PlayerVideoConfigStore>) {
    this.key = (!!src && src.key) || '';
    this.url = (!!src && src.url) || '';
  }
}

export class PlayerConfigStore {
  public id: number;
  public videos: PlayerVideoConfigStore[];

  constructor(src?: Partial<PlayerConfigStore>) {
    this.id = (!!src && src.id) || 0;
    this.videos =
      !!src && !!src.videos
        ? src.videos.map((v) => new PlayerVideoConfigStore(v))
        : [];
  }
}

export class ConfigStore {
  public players: PlayerConfigStore[];

  constructor(src?: Partial<ConfigStore>) {
    this.players =
      !!src && !!src.players
        ? src.players.map((p) => new PlayerConfigStore(p))
        : [];
  }
}
