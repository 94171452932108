import './welcome.scss';

import { Component, h } from 'preact';
import { RoutableProps } from 'preact-router';

import { ActM, CnstM, ImgM, SrvM } from '../modules';
import { Routes } from '../routes';

interface IProps extends RoutableProps {
  onStreamChanged?: (stream: MediaStream) => void;
}

export class WelcomePage extends Component<IProps, any> {
  public render() {
    const device = SrvM.DeviceService.detectDevice();
    const isWebView = device.isWebView;
    const openTip = SrvM.i18n.txt(
      device.iOS ? CnstM.StringKey.OpenInSafari : CnstM.StringKey.OpenInChrome
    );
    return (
      <div className="welcome-container center-page">
        <div className="welcome-content">
          <span className="page-title-bold title-glow">
            {SrvM.i18n.txt(CnstM.StringKey.Welcome)}
          </span>
          <img className="team-img" src={ImgM.Team} />
          <span className="welcome-text text-glow">
            {SrvM.i18n.txt(CnstM.StringKey.WeBringingTheStadiumExperience)}
          </span>
          {isWebView && <div className="warning">{openTip}</div>}
          {!isWebView && (
            <button className="button1" onClick={this.handleStartButtonClicked}>
              {SrvM.i18n.txt(CnstM.StringKey.Start)}
            </button>
          )}
        </div>
      </div>
    );
  }

  private handleStartButtonClicked = () => {
    ActM.AppActions.route(Routes.Players);
  };
}
