import { ImageKey, Language, StorageKey, StringKey } from '../../constants';
import { Images, Strings } from './i18nDictionary';

export class i18n {
  public static setLang = (lang: Language) =>
    sessionStorage.setItem(StorageKey.Lang, lang);

  public static getLang = () =>
    (sessionStorage.getItem(StorageKey.Lang) as Language) || Language.EN;

  public static txt = (key: StringKey): string =>
    Strings[key][i18n.getLang()] || Strings[key][Language.EN];

  public static img = (key: ImageKey) =>
    Images[key][i18n.getLang()] || Images[key][Language.EN];
}
