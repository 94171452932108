import CameraSelectArrowActive from '../assets/img/cameraSelectArrowActive.png';
import CameraSelectArrowInactive from '../assets/img/cameraSelectArrowInactive.png';
import _RetiredPlayer1 from '../assets/img/cooper_retired.png';
import DevicePosition from '../assets/img/devicePosition.png';
import Player10 from '../assets/img/diggs.png';
import _RetiredPlayer4 from '../assets/img/elliott_retired.png';
import Player8 from '../assets/img/lamb.png';
import Player7 from '../assets/img/lawrence.png';
import Logo from '../assets/img/logo.png';
import _RetiredPlayer2 from '../assets/img/martin_retired.png';
import Player9 from '../assets/img/parsons.png';
import PersonLeftActive from '../assets/img/personLeftActive.png';
import PersonLeftInactive from '../assets/img/personLeftInactive.png';
import PersonRightActive from '../assets/img/personRightActive.png';
import PersonRightInactive from '../assets/img/personRightInactive.png';
import PhoneActive from '../assets/img/phoneActive.png';
import PhoneInactive from '../assets/img/phoneInactive.png';
import PhotoHeader from '../assets/img/photoHeader.png';
import PlaceCircle from '../assets/img/placeCircle.png';
import Player3 from '../assets/img/prescott.png';
import Player11 from '../assets/img/smith.png';
import _RetiredPlayer12 from '../assets/img/pollard_retired.png';
import _RetiredPlayer13 from '../assets/img/gilmore.png';
import Player14 from '../assets/img/elliott.png';
import Player15 from '../assets/img/ferguson.png';
import _RetiredPlayer6 from '../assets/img/smith_retired.png';
import StandHere from '../assets/img/standHere.png';
import Star1 from '../assets/img/star1.png';
import Star2 from '../assets/img/star2.png';
import Star3 from '../assets/img/star3.png';
import Star4 from '../assets/img/star4.png';
import Star5 from '../assets/img/star5.png';
import Step1 from '../assets/img/step1.svg';
import Step2 from '../assets/img/step2.svg';
import Step3 from '../assets/img/step3.svg';
import Team from '../assets/img/team.png';
import _RetiredPlayer5 from '../assets/img/vander.png';
import LeftVector from '../assets/img/vectorleft.png';

const Steps = [Step1, Step2, Step3];
const Players = [
  _RetiredPlayer1,
  _RetiredPlayer2,
  Player3,
  _RetiredPlayer4,
  _RetiredPlayer5,
  _RetiredPlayer6,
  Player7,
  Player8,
  Player9,
  Player10,
  Player11,
  _RetiredPlayer12,
  _RetiredPlayer13,
  Player14,
  Player15,
];
const Stars = [Star1, Star2, Star3, Star4, Star5];

export {
  Steps,
  Players,
  Stars,
  Logo,
  Team,
  PlaceCircle,
  LeftVector,
  StandHere,
  PhotoHeader,
  PersonLeftInactive,
  PersonRightInactive,
  CameraSelectArrowInactive,
  PersonLeftActive,
  PersonRightActive,
  CameraSelectArrowActive,
  PhoneActive,
  PhoneInactive,
  DevicePosition,
};
