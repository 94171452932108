// en
import Footer_EN from '../../../assets/img/en/footer.png';
// es
import Footer_ES from '../../../assets/img/en/footer.png';
import { ImageKey, StringKey } from '../../constants';

export const Strings = {
  [StringKey.Back]: { en: 'Back', es: 'espalda' },
  [StringKey.ByCheckingTheFollowing]: {
    en: 'By checking the following...',
    es: 'Comprobando lo siguiente...',
  },
  [StringKey.CameraError]: {
    en: 'This experience requires camera access',
    es: 'Esta experiencia requiere acceso a la cámara',
  },
  [StringKey.CapturePhoto]: { en: 'Capture photo', es: 'Capturar foto' },
  [StringKey.CheckEmailOrText]: {
    en: 'Check your email inbox or text messages for your photo and gif.',
    es: 'Revise su bandeja de entrada de correo electrónico o mensajes de texto para su foto y gif.',
  },
  [StringKey.Continue]: { en: 'Continue', es: 'Continuar' },
  [StringKey.ControlScreen]: {
    en: 'Control the Screen with Your Phone',
    es: 'Controla la pantalla con tu teléfono',
  },
  [StringKey.Email]: { en: 'Your Email', es: 'Your E-mail' },
  [StringKey.EmailPlaceholder]: {
    en: 'youremail@website.com',
    es: 'youremail@website.com',
  },
  [StringKey.DesktopAlert]: {
    en: 'You must be on a mobile device to use this experience',
    es: 'Debes estar en un dispositivo móvil para usar esta experiencia',
  },
  [StringKey.DownloadYourPhoto]: {
    en: 'download your photo',
    es: 'descarga tu foto',
  },
  [StringKey.ShareYourPhoto]: {
    en: 'share your photo',
    es: 'comparte tu foto',
  },
  [StringKey.English]: { en: 'English' },
  [StringKey.EnterMobile]: {
    en: 'Please enter your mobile number',
    es: 'Por favor, introduzca su número de móvil',
  },
  [StringKey.EnterEmail]: {
    en: 'Please enter your email',
    es: 'Por favor introduzca su correo electrónico',
  },
  [StringKey.EnterInfo]: {
    en: 'Enter Your Sharing Information',
    es: 'Ingrese su información para compartir',
  },
  [StringKey.EnterYourInfo]: { en: 'Confirm Your Age', es: 'Confirm Your Age' },
  [StringKey.ToGetPhoto]: { en: 'To Get Your Photo', es: 'To Get Your Photo' },
  [StringKey.GetStarted]: { en: 'Get Started', es: 'Empezar' },
  [StringKey.GetReady]: { en: 'Get Ready!', es: 'Prepararse!' },
  [StringKey.HoldQR]: {
    en: 'Please hold QR code to the kiosk camera',
    es: 'Mantenga el código QR en la cámara del quiosco',
  },
  [StringKey.Instructions]: {
    en: 'Prop up your phone, and press continue to get ready for your photo',
    es: 'Levanta tu teléfono y presiona continuar para prepararte para tu foto',
  },
  [StringKey.LevelPhone]: { en: 'Level Your Phone', es: 'Nivele su teléfono' },
  [StringKey.LevelPhoneToBegin]: { en: 'To Begin', es: 'para comenzar' },
  [StringKey.OptIn]: {
    en: 'I confirm that I would like to receive promotional emails from AT&T (optional)',
    es: 'Confirmo que me gustaría recibir correos electrónicos promocionales de AT&T (opcional)',
  },
  [StringKey.Reset]: { en: 'Reset', es: 'Reiniciar' },
  [StringKey.OpenInChrome]: {
    en: 'Open in Chrome to proceed',
    es: 'Open in Chrome to proceed',
  },
  [StringKey.OpenInSafari]: {
    en: 'Open in Safari to proceed',
    es: 'Open in Safari to proceed',
  },
  [StringKey.RotateTitle]: {
    en: 'Please rotate your device',
    es: 'Por favor gire su dispositivo',
  },
  [StringKey.RotateSubtitle]: {
    en: 'This page can only be viewed in portrait',
    es: 'Esta página solo se puede ver en vertical',
  },
  [StringKey.SelectCamera]: {
    en: 'please select a camera to use',
    es: 'seleccione una cámara para usar',
  },
  [StringKey.SeflieMode]: { en: 'selfie mode', es: 'modo selfie' },
  [StringKey.SeflieModeDescription]: {
    en: 'take a photo of yourself',
    es: 'Apoya el teléfono de la gira y tómate una foto',
  },
  [StringKey.SkipSharing]: { en: 'Skip sharing', es: 'Omitir compartir' },
  [StringKey.Spanish]: { en: 'Español' },
  [StringKey.Start]: { en: 'Start', es: 'Comienzo' },
  [StringKey.Step]: { en: 'Step', es: 'Paso' },
  [StringKey.Submit]: { en: 'Continue', es: 'Enviar' },
  [StringKey.TakeAnotherPhoto]: {
    en: 'take another photo',
    es: 'toma otra foto',
  },
  [StringKey.TagOnSocial]: {
    en: 'Tag us on social @ATT and #PoseWithThePros',
    es: 'Etiquétanos en las redes sociales @ATT y #PoseWithThePros',
  },
  [StringKey.Text]: { en: 'Text', es: 'Texto' },
  [StringKey.Thanks]: { en: 'Thanks for posing!', es: '¡Gracias por posar!' },
  [StringKey.Timeout]: {
    en: 'Your session has timed out or become disconnected. Press continue to re-start.',
    es: 'Su sesión ha expirado o se ha desconectado. Presione continuar para reiniciar.',
  },
  [StringKey.TOS]: {
    en: 'I confirm that I am 18 years of age or older',
    es: 'Confirmo que soy mayor de 18 años',
  },
  [StringKey.YouAreOutside]: {
    en: 'While Cowboys Nation is limitless, you are outside the activation area for this experience. \r\n\r\nPlease try again when you are closer to Dallas',
    es: 'Si bien Cowboys Nation no tiene límites, estás fuera del área de activación de esta experiencia. \r\n\r\nVuelva a intentarlo cuando esté más cerca de Dallas.',
  },
  [StringKey.TurnOnGPS]: {
    en: 'We need access to your GPS to use this experience. \r\n\r\nPlease make sure your GPS is on, reload the page, grant GPS permission to proceed.',
    es: 'Necesitamos acceso a su GPS para usar esta experiencia. \r\n\r\nAsegúrese de que su GPS esté encendido, vuelva a cargar la página y conceda permiso al GPS para continuar.',
  },
  [StringKey.RearCamera]: { en: 'rear camera', es: 'cámara trasera' },
  [StringKey.RearCameraDescription]: {
    en: 'take a photo of your\n\rfriends and family',
    es: 'toma una foto de tus amigos y familiares',
  },
  [StringKey.RequiredText]: {
    en: '(required)',
    es: '(obligatorio)',
  },
  [StringKey.TryAgain]: { en: 'Try Again', es: 'Inténtalo de nuevo' },
  [StringKey.Welcome]: { en: 'Welcome', es: 'Bienvenidos' },
  [StringKey.LookAtBigScreen]: {
    en: 'Look at the big screen, not me',
    es: 'Mira la pantalla grande, no yo',
  },
  [StringKey.EmailPopup]: {
    en: 'Please enter a valid email address.',
    es: 'Please enter a valid email address.',
  },
  [StringKey.PhonePopup]: {
    en: 'Please enter a valid phone number.',
    es: 'Please enter a valid phone number.',
  },
  [StringKey.CheckboxPopup]: {
    en: 'Please check the required box.',
    es: 'Please check the required box.',
  },
  [StringKey.SharingPopup]: {
    en: 'Are you sure you want to skip receiving your photo?',
    es: 'Are you sure you want to skip receiving your photo?',
  },
  [StringKey.ShareFacebook]: {
    en: 'share to facebook',
    es: 'compartir en Facebook',
  },
  [StringKey.ShareTwitter]: {
    en: 'share to X',
    es: 'compartir en X',
  },
  [StringKey.WeBringingTheStadiumExperience]: {
    en: "We're bringing the AT&T stadium experience\r\ninto the palm of your hand",
    es: 'Llevamos la experiencia del estadio de AT&T a la palma de su mano',
  },
};

export const Images = {
  [ImageKey.Footer]: { en: Footer_EN, es: Footer_ES },
};
