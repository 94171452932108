export enum PlayerPosition {
  BottomCenter = 'BottomCenter',
  BottomLeft = 'BottomLeft',
  BottomRight = 'BottomRight',
  TopLeft = 'TopLeft',
  TopRight = 'TopRight',
}

export class Player {
  id: number;
  name: string;
  position: PlayerPosition;
  key?: string;
}

export class PlayerVideo {
  id: number;
  name: string;
  key?: string;
  intro?: HTMLVideoElement;
  loop?: HTMLVideoElement;
  outro?: HTMLVideoElement;
}

export enum VideoParts {
  Intro = 'Intro',
  Loop = 'Loop',
  Outro = 'Outro',
}

export interface IUrlsData {
  key: string;
  imgName: string;
  imgUploadData: ISignedPostData;
  jsonName: string;
  jsonPostData: ISignedPostData;
}

export interface ISignedPostData {
  fields: any;
  url: string;
}

export enum GAEvents {
  GeoCheck = 'geocheck',
  Download = 'download',
  ShareDownload = 'share_download',
  Sharing = 'sharing',
}

export enum GeoCheckResult {
  Outside = 'outside',
  Inside = 'inside',
}
