import './cameraSelect.scss';

import { Component, h } from 'preact';
import { RoutableProps } from 'preact-router';

import { CameraType, Constants, StorageKey } from '../constants';
import { ActM, CnstM, ImgM, SrvM } from '../modules';
import { Routes } from '../routes';

interface IProps extends RoutableProps {
  onAllowMotion?: (isAllow: boolean) => void;
  onStreamChanged?: (stream: MediaStream) => void;
}

interface IState {
  isSubmitEnabled: boolean;
  cameraTypes: ICameraType[];
  selectedCameraType: string;
  isHaveError: boolean;
  errorMessage: string;
  canSelect: boolean;
}

interface ICameraType {
  header: string;
  discription: string;
  leftActiveImage: any;
  leftInactiveImage: any;
  rightActiveImage: any;
  rightInactiveImage: any;
  type: CnstM.CameraType;
}

export class CameraSelectPage extends Component<IProps, IState> {
  state = {
    isSubmitEnabled: false,
    cameraTypes: [],
    selectedCameraType: '',
    isHaveError: false,
    errorMessage: '',
    canSelect: true,
  };

  public componentDidMount() {
    this.setState({
      isSubmitEnabled: false,
      cameraTypes: [
        {
          header: SrvM.i18n.txt(CnstM.StringKey.RearCamera),
          discription: SrvM.i18n.txt(CnstM.StringKey.RearCameraDescription),
          type: CnstM.CameraType.Rear,
          leftActiveImage: ImgM.PersonLeftActive,
          leftInactiveImage: ImgM.PersonLeftInactive,
          rightActiveImage: ImgM.PersonRightActive,
          rightInactiveImage: ImgM.PersonRightInactive,
        },
        {
          header: SrvM.i18n.txt(CnstM.StringKey.SeflieMode),
          discription: SrvM.i18n.txt(CnstM.StringKey.SeflieModeDescription),
          type: CnstM.CameraType.Front,
          leftActiveImage: ImgM.PhoneActive,
          leftInactiveImage: ImgM.PhoneInactive,
          rightActiveImage: ImgM.PersonRightActive,
          rightInactiveImage: ImgM.PersonRightInactive,
        },
      ],
    });
  }

  public render() {
    return (
      <div class="camera-select-container center-page">
        <span className="page-title camera-type-page-header select-title-glow">
          {SrvM.i18n.txt(CnstM.StringKey.SelectCamera)}
        </span>
        <div>
          {this.state.cameraTypes.map((cameraType, index) => {
            const active = cameraType.type === this.state.selectedCameraType;
            const buttonStyle = active
              ? 'camera-type-button-active'
              : 'camera-type-button';
            return (
              <button
                key={index}
                className={buttonStyle}
                onClick={() => this.handleSelectCamera(cameraType)}
              >
                <div className="camera-type-button-container">
                  <span className="camera-type-header">
                    {cameraType.header}
                  </span>
                  <div className="camera-type-button-bottom-container">
                    <img
                      className="camera-type-person-img"
                      src={
                        active
                          ? cameraType.leftActiveImage
                          : cameraType.leftInactiveImage
                      }
                    />
                    <div className="camera-type-arrow-container">
                      <img
                        className="camera-type-arrow-img"
                        src={
                          active
                            ? ImgM.CameraSelectArrowActive
                            : ImgM.CameraSelectArrowInactive
                        }
                      />
                      <span className="camera-type-description">
                        {cameraType.discription}
                      </span>
                    </div>
                    <img
                      className="camera-type-person-img"
                      src={
                        active
                          ? cameraType.rightActiveImage
                          : cameraType.rightInactiveImage
                      }
                    />
                  </div>
                </div>
              </button>
            );
          })}
        </div>
        <button
          className="button2 button-mar"
          onClick={this.handleSubmit}
          disabled={!this.state.isSubmitEnabled}
        >
          {SrvM.i18n.txt(CnstM.StringKey.Submit)}
        </button>
        {this.state.isHaveError ? (
          <div class="page-popup-container">
            <div class="page-popup">
              <h1 class="page-popup-message">{this.state.errorMessage}</h1>
              <button
                class="button3"
                type="button"
                onClick={() => this.handleContinue()}
              >
                {SrvM.i18n.txt(CnstM.StringKey.TryAgain)}
              </button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  private handleSelectCamera = (cameraType: ICameraType) => {
    if (this.state.canSelect) {
      if (this.state.selectedCameraType === cameraType.type) return;

      this.setState({ selectedCameraType: cameraType.type });
      SrvM.Logger.log('handleSelectCamera', cameraType.type);
      this.setState({ isSubmitEnabled: true });
    }
  };

  private handleSubmit = () => {
    this.setState({ canSelect: false });
    sessionStorage.setItem(
      StorageKey.CameraType,
      this.state.selectedCameraType
    );
    this.getPermissionsAndRunCamera();
  };

  private handleContinue = () => {
    this.setState({ isHaveError: false, errorMessage: '' });
  };

  private getPermissionsAndRunCamera() {
    const deviceOrientationEvent: any = window.DeviceOrientationEvent;
    if (deviceOrientationEvent) {
      if (typeof deviceOrientationEvent.requestPermission === 'function') {
        deviceOrientationEvent
          .requestPermission()
          .then((s) => {
            if (s === 'denied') {
              this.props.onAllowMotion(false);
            } else {
              this.props.onAllowMotion(true);
            }
            this.requestCameraPermissions();
          })
          .catch((err) => {
            this.props.onAllowMotion(false);
            this.requestCameraPermissions();
            SrvM.Logger.error('An error occurred: ' + err);
          });
      } else {
        this.props.onAllowMotion(true);
        this.requestCameraPermissions();
      }
    } else {
      this.props.onAllowMotion(false);
      this.requestCameraPermissions();
    }
  }

  private requestCameraPermissions = () => {
    const device = SrvM.DeviceService.detectDevice();
    const constraints: MediaStreamConstraints | any = {
      video: {
        width: Constants.CameraWidth,
        height: Constants.CameraHeight,
        frameRate: Constants.FrameRate,
        facingMode: this.state.selectedCameraType,
        resizeMode: 'crop-and-scale',
        aspectRatio: Constants.AspectRatio,
      },
      audio: false,
    };
    SrvM.Logger.log(
      `Asking media constraints for ${device.iOS ? 'iOS' : 'Android'} are ${JSON.stringify(constraints, null, 2)}`
    );

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => this.continueWithStream(stream))
        .catch((err) => {
          this.setState({
            isHaveError: true,
            errorMessage: SrvM.i18n.txt(CnstM.StringKey.CameraError),
          });
          SrvM.Logger.error('getUserMedia error occurred: ' + err, err);
          if (err.name === 'NotReadableError') {
            SrvM.Logger.log('Is that Samsung device?');
          }
          /*
                    to repro set these constraints:
                    height: { ideal: 1920, min: 1920, max: 1920 },
                    width: { ideal: 1080, min: 1080, max: 1080 },
                 
                    */
          if (
            err.name === 'OverconstrainedError' ||
            err.name === 'ConstraintNotSatisfiedError'
          ) {
            // then we could not find the camera with correct input/output
          }

          SrvM.Logger.log(`Let's try to get camera other way`);
          navigator.mediaDevices
            .enumerateDevices()
            .then((devices: MediaDeviceInfo[]) => {
              SrvM.Logger.log(`enumerateDevices: ${JSON.stringify(devices)}`);
              const cameras = devices.filter((d) => d.kind === 'videoinput');
              if (!cameras.length) {
                SrvM.Logger.warn('No cameras detected');
              } else {
                const lastCamera = cameras[cameras.length - 1];
                const constraintsSecondTry: MediaStreamConstraints = {
                  video: {
                    height: Constants.CameraHeight,
                    width: Constants.CameraWidth,
                    deviceId: lastCamera.deviceId,
                  },
                  audio: false,
                };
                SrvM.Logger.log(
                  `getUserMedia: ${JSON.stringify(constraintsSecondTry)}`
                );
                navigator.mediaDevices
                  .getUserMedia(constraintsSecondTry)
                  .then((stream) => this.continueWithStream(stream))
                  .catch((err) => {
                    SrvM.Logger.error(
                      'getUserMedia2 error occurred: ' + err,
                      err,
                      constraintsSecondTry
                    );
                  });
              }
            })
            .catch((e) =>
              SrvM.Logger.error(`Failed to enumerate devices: ${e} | `, e)
            );
        });
    } else {
      // no camera or old browser - not supported
      this.setState({
        isHaveError: true,
        errorMessage: SrvM.i18n.txt(CnstM.StringKey.CameraError),
      });
    }
  };

  private continueWithStream = (stream) => {
    SrvM.Logger.log('Received camera media stream');
    if (this.props.onStreamChanged) this.props.onStreamChanged(stream);

    this.state.selectedCameraType === CameraType.Rear
      ? ActM.AppActions.route(Routes.Camera)
      : ActM.AppActions.route(Routes.Instructions);
  };
}
