import * as Branding from '../../branding/branding.json';
import { i18n } from './i18n';

const baseStyles = `
  color: #ffffff;
  border-radius: 5px;
`;

const infoStyles = `
  background: #0b8fcf;
  ${baseStyles}
`;

const warnStyles = `
  background: #ffc400;
  ${baseStyles}
`;

const errorStyles = `
  background: #ff0000;
  ${baseStyles}
`;

export class Logger {
  public static track(
    name: string,
    params?: Gtag.CustomParams | Gtag.ControlParams | Gtag.EventParams,
    command: 'event' = 'event'
  ) {
    if (Branding.analytics[ENVIRONMENT]?.google?.enabled && gtag) {
      gtag(command, name, {
        language: i18n.getLang(),
        ...params,
      });
    }
  }

  public static log(msg: string, ...optionalParams: any[]) {
    console.info(
      `%c ${Branding.shortAppName} %c ${msg}`,
      infoStyles,
      null,
      optionalParams
    );
  }

  public static warn(msg: string, ...optionalParams: any[]) {
    console.warn(
      `%c ${Branding.shortAppName} %c ${msg}`,
      warnStyles,
      null,
      optionalParams
    );
  }

  public static error(msg: string, ...optionalParams: any[]) {
    console.error(
      `%c ${Branding.shortAppName} %c ${msg}`,
      errorStyles,
      null,
      optionalParams
    );
  }
}
