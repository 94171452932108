import './userForm.scss';

import { Component, h } from 'preact';

import { Checkbox } from '../components';
import { StorageKey } from '../constants';
import { ActM, CnstM, SrvM } from '../modules';
import { Routes } from '../routes';

interface IUserFormState {
  contactInfo: string;
  canSubmit: boolean;
  tosAgreed: boolean;
  optIn: boolean;
}

export class UserFormPage extends Component<any, IUserFormState> {
  state = {
    contactInfo: '',
    canSubmit: false,
    tosAgreed: false,
    optIn: false,
  };

  img: string;
  viewportMetaTag: string;
  android = SrvM.DeviceService.detectDevice().android;

  constructor() {
    super();
    this.img = sessionStorage.getItem(StorageKey.FinalImage);
  }

  public componentWillMount() {
    const contactInfo = '';
    const tosAgreed =
      sessionStorage.getItem(CnstM.StorageKey.TOSAgreed) === '1';
    const optIn = false;
    this.setState({
      contactInfo: contactInfo,
      tosAgreed: tosAgreed,
      optIn: optIn,
    });
    if (this.android) {
      this.setScreenSizeForDroid();
    }
  }

  public componentDidMount() {
    this.validate();
  }

  public componentWillUnmount() {
    if (this.android) {
      this.dropScreenSizeForDroid();
    }
  }

  public setScreenSizeForDroid() {
    document.documentElement.style.setProperty('overflow', 'auto');
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const metaViewport = document.querySelector(`meta[name=viewport]`);
    this.viewportMetaTag = metaViewport.getAttribute('content');
    metaViewport.setAttribute(
      'content',
      `height=${height}px, width=device-width, initial-scale=1.0`
    );
  }

  public dropScreenSizeForDroid() {
    document.documentElement.style.setProperty('overflow', 'hidden');
    const metaViewport = document.querySelector(`meta[name=viewport]`);
    metaViewport.setAttribute(`content`, this.viewportMetaTag);
  }

  public render() {
    const pageClasses = 'page-form align-cc page-form-email';
    const pageTitle = SrvM.i18n.txt(CnstM.StringKey.EnterYourInfo);
    const pageSubTitle = SrvM.i18n.txt(CnstM.StringKey.ToGetPhoto);
    return (
      <section class={pageClasses}>
        <div class="page-title info-title-glow">{pageTitle}</div>
        <div class="page-sub-title info-title-glow">{pageSubTitle}</div>
        <img className="final-image" src={this.img}></img>
        <form class="align-cc" onSubmit={this.handleSubmit}>
          <span class="check-header text-glow">
            {SrvM.i18n.txt(CnstM.StringKey.ByCheckingTheFollowing)}
          </span>
          <div class="check-group">
            <Checkbox
              name="tosAgreed"
              required
              checked={this.state.tosAgreed}
              label={SrvM.i18n.txt(CnstM.StringKey.TOS)}
              onChange={this.handleFieldChange}
            />
          </div>
          <button class="button3 submit-mar">
            {SrvM.i18n.txt(CnstM.StringKey.Submit)}
          </button>
          {/* <div className='privacy'>
                        <a href='https://about.att.com/csr/home/privacy/full_privacy_policy.html' target='_blank'>Privacy Policy</a>
                    </div> */}
        </form>
      </section>
    );
  }

  // handlers

  private handleFieldChange = (e: any) => {
    const isCheckbox = e.target.type === 'checkbox';
    const value = isCheckbox ? e.target.checked : e.target.value;
    this.setState(
      {
        [e.target.name]: value,
      },
      () => this.validate()
    );
  };

  private handleSubmit = async (e: any) => {
    if (e) e.preventDefault();

    if (this.state.canSubmit) {
      await SrvM.DataService.uploadUserFormData(
        this.state.contactInfo,
        this.state.optIn,
        this.state.tosAgreed
      );
      ActM.AppActions.route(Routes.Thanks);
    } else {
      sessionStorage.setItem(
        CnstM.StorageKey.ContactInfo,
        this.state.contactInfo
      );
      sessionStorage.setItem(
        CnstM.StorageKey.TOSAgreed,
        this.state.tosAgreed ? '1' : '0'
      );
      sessionStorage.setItem(
        CnstM.StorageKey.OptInAccepted,
        this.state.optIn ? '1' : '0'
      );
      ActM.AppActions.route(Routes.Submit);
    }
  };

  // helpers

  private validate(): boolean {
    const { tosAgreed } = this.state;

    let canSubmit = true;

    if (!tosAgreed) {
      canSubmit = false;
      this.saveValidationError(SrvM.i18n.txt(CnstM.StringKey.CheckboxPopup));
      this.setState({ canSubmit });
      return canSubmit;
    }

    this.setState({ canSubmit });
    return canSubmit;
  }

  private saveValidationError(text: string) {
    sessionStorage.setItem(CnstM.StorageKey.Submit, text);
  }
}
