import './players.scss';

import { Component, h } from 'preact';
import { RoutableProps } from 'preact-router';

import LoadingImage from '../../assets/img/loading.png';
import { Player, PlayerVideo } from '../models';
import { ActM, Branding, CnstM, ImgM, SrvM } from '../modules';
import { Routes } from '../routes';
import { PlayerManagerService, PreloadService } from '../services';

const Positions: Map<number, string> = new Map([
  [0, 'top-video'],
  [1, 'high-video'],
  [2, 'middle-video'],
  [3, 'low-video'],
  [4, 'bottom-video'],
]);

const VideoEndTimePercent = 0.95;

let selectedPlayers: Player[] = [null, null, null, null, null];

interface IPlayer {
  id: number;
  firstName: string;
  lastName: string;
  photo: string;
  key?: string;
}

interface IProps extends RoutableProps {
  isLoading: boolean;
  onSelectPlayers: (players: Player[]) => void;
}

interface IStates {
  selectedPlayerIds: number[];
  loading: boolean;
}

export class PlayersPage extends Component<IProps, IStates> {
  state = {
    selectedPlayerIds: [],
    loading: false,
  };
  playersVideo: PlayerVideo[];
  isSubmitEnabled =
    this.state.selectedPlayerIds.length === CnstM.Constants.MaxPlayersSelected;
  players: IPlayer[] = [];

  constructor() {
    super();

    this.players = Branding.team.players.map((p) => {
      return {
        ...p,
        photo: ImgM.Players[p.id - 1],
      } as IPlayer;
    });
  }

  public render() {
    return (
      <div className="players-page-root">
        {!this.props.isLoading ? (
          <div>
            <div className="stars-container">
              <img className="star-top" src={ImgM.Stars[0]} />
              <img className="star-high" src={ImgM.Stars[1]} />
              <img className="star-middle" src={ImgM.Stars[2]} />
              <img className="star-low" src={ImgM.Stars[3]} />
              <img className="star-bottom" src={ImgM.Stars[4]} />
            </div>
            <div className="players-container">
              <div className="page-select">SELECT</div>
              <div className="page-five-players">FIVE PLAYERS</div>
              <div
                className={
                  'players-list' +
                  (this.state.selectedPlayerIds.length ===
                  CnstM.Constants.MaxPlayersSelected
                    ? ' all-selected'
                    : '')
                }
              >
                {this.players.map((player) => {
                  const isSelected = this.state.selectedPlayerIds.some(
                    (p) => p === player.id
                  );
                  return (
                    <div
                      key={player.id}
                      className={`player ${
                        isSelected ? 'player-selected' : ''
                      }`}
                      onClick={() => this.handleSelectPlayer(player.id)}
                    >
                      <div className="player-img-wrapper align-cc">
                        <img className="player-img" src={player.photo} />
                      </div>
                      <div className="player-first-name">
                        {player.firstName}
                      </div>
                      <div className="player-last-name">{player.lastName}</div>
                    </div>
                  );
                })}
              </div>

              {/*<button className='button1 button-mar action-button' onClick={() => this.handleReset()}>
                                {SrvM.i18n.txt(CnstM.StringKey.Reset)}
                            </button>*/}
              <button
                className="button2 button-mar action-button submit-button"
                onClick={(e) => this.handleSubmit(e)}
                disabled={
                  this.state.selectedPlayerIds.length !==
                  CnstM.Constants.MaxPlayersSelected
                }
              >
                {SrvM.i18n.txt(CnstM.StringKey.Submit)}
              </button>
            </div>
          </div>
        ) : null}

        {this.props.isLoading ? (
          <div className="top-container loading-view">
            <h2>&nbsp;</h2>
            <img src={LoadingImage} className="rotating loading-item" />
            <h2>Please wait...</h2>
          </div>
        ) : null}
      </div>
    );
  }

  componentDidMount() {
    if (PreloadService.MenuPreloadComplete) {
      this.playersVideo = PreloadService.PlayerMenuVideos;
    } else {
      PreloadService.MenuPreload.then(() => {
        this.playersVideo = PreloadService.PlayerMenuVideos;
      });
    }
  }

  private handleSubmit(element: any) {
    element.target.disabled = true;
    this.hideAllPlayers().then(() => {
      SrvM.Logger.log(`all players hidden`);
      SrvM.PreloadService.cleanupMenuResources();
      ActM.AppActions.route(Routes.CameraSelect);
      SrvM.PreloadService.startPreloadForCamera(selectedPlayers);

      //GA4 Code addition - Adi
      // Map selectedPlayers array to include both firstName and lastName
      const selectedPlayersWithNames = selectedPlayers.map((selectedPlayer) => {
        // Find the corresponding player object from the players[] array using the id
        const player = this.players.find((p) => p.id === selectedPlayer.id);

        // If the player with the corresponding id is found, update the name property
        if (player) {
          const updatedName = `${player.firstName}_${player.lastName}`;
          SrvM.Logger.track('PlayerSelect_' + updatedName);
        }
        return selectedPlayer;
      });  
    });
    selectedPlayers = PlayerManagerService.setPositions(selectedPlayers);
    this.props.onSelectPlayers(selectedPlayers);
  }

  private handleReset = () => {
    this.setState({ selectedPlayerIds: [] });
    const playerList = selectedPlayers;
    selectedPlayers = [null, null, null, null, null];
    this.hideAllPlayers(playerList).then(() => {
      SrvM.Logger.log(`all players hidden`);
    });
  };

  private handleSelectPlayer = (id: number) => {
    const ids = this.state.selectedPlayerIds;
    let selectedPlayer: Player = null;
    const isSelected = ids.some((p) => p === id);
    if (isSelected) {
      const index = ids.findIndex((p) => p === id);
      if (index > -1) {
        ids.splice(index, 1);

        const player = this.players.find((p) => p.id === id);
        const playerIndex = selectedPlayers.findIndex(
          (p) => p !== null && p.name == player.firstName
        );
        if (playerIndex > -1) {
          selectedPlayer = selectedPlayers[playerIndex];
          selectedPlayers[playerIndex] = null;
          this.hidePlayer(selectedPlayer, playerIndex);
        }
      }
    } else if (ids.length < CnstM.Constants.MaxPlayersSelected) {
      //TODO cant do that with state
      ids.push(id);

      const player = this.players.find((p) => p.id == id);
      if (player) {
        const emptyPosition = this.getFirstEmptyPosition();

        if (emptyPosition > -1) {
          selectedPlayer = {
            id: player.id,
            name: player.firstName,
            position: null,
            key: player.key,
          };
          selectedPlayers[emptyPosition] = selectedPlayer;
          this.showPlayer(selectedPlayer, emptyPosition);
        }
      }
    }

    this.setState({ selectedPlayerIds: ids });
  };

  private getFirstEmptyPosition = () => {
    let emptyPosition = -1;
    selectedPlayers.forEach((p, i) => {
      if (p == null && emptyPosition == -1) {
        emptyPosition = i;
      }
    });

    return emptyPosition;
  };

  private showPlayer(selectedPlayer: Player, position: number) {
    const player = this.playersVideo.find((p) => p.name == selectedPlayer.name);
    player.intro.currentTime = 0;
    player.loop.currentTime = 0;
    const pos = Positions.get(position);
    player.intro.onended = () => {
      SrvM.Logger.log(`start loop playback ${selectedPlayer.name}`);
      player.loop.play();
      player.loop.classList.add(pos);

      player.intro.className = '';
      SrvM.Logger.log(`pause intro ${selectedPlayer.name}`);
      player.intro.pause();
    };

    if (!player.outro.paused) {
      player.outro.className = '';
      SrvM.Logger.log(`pause outro ${selectedPlayer.name}`);
      player.outro.pause();
    }
    SrvM.Logger.log(`start intro playback ${selectedPlayer.name}`);
    player.intro.play();
    player.intro.classList.add(pos);
  }

  private hidePlayer(selectedPlayer: Player, position: number): Promise<any> {
    return new Promise((resolve, reject) => {
      if (selectedPlayer === null) {
        resolve(null);
        return;
      }
      const player = this.playersVideo.find(
        (p) => p.name == selectedPlayer.name
      );
      const pos = Positions.get(position);
      player.outro.currentTime = 0;
      player.outro.ontimeupdate = () => {
        if (!player.outro) return;
        try {
          if (
            player.outro.currentTime / player.outro.duration >=
            VideoEndTimePercent
          ) {
            SrvM.Logger.log(`hide. ontimeupdate ${selectedPlayer.name}`);
            player.outro.pause();
            player.outro.className = '';
            resolve(null);
          }
        } catch (err) {
          SrvM.Logger.error(err);
          reject();
        }
      };
      if (!player.intro.paused) {
        player.intro.className = '';
        SrvM.Logger.log(`intro paused ${selectedPlayer.name}`);
        player.intro.pause();
      }

      if (!player.loop.paused) {
        player.loop.className = '';
        SrvM.Logger.log(`loop paused ${selectedPlayer.name}`);
        player.loop.pause();
      }
      SrvM.Logger.log(`start outro playback ${selectedPlayer.name}`);
      player.outro.play();
      player.outro.classList.add(pos);
    });
  }

  private hideAllPlayers(players?: Player[]): Promise<HTMLVideoElement[]> {
    const playersPromises =
      players !== undefined
        ? players.map((p, i) => this.hidePlayer(p, i))
        : selectedPlayers.map((p, i) => this.hidePlayer(p, i));
    return Promise.all<HTMLVideoElement>(playersPromises);
  }
}
