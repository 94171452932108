import './thanks.scss';

import { Component, h } from 'preact';

import { StorageKey } from '../constants';
import { Branding, CnstM, ModM, SrvM } from '../modules';

export class ThanksPage extends Component<any, any> {
  img: string;
  imageUrl = '';
  fileName = 'cowboys-at-home-mobile.jpeg';
  title: string = encodeURIComponent('Catch your team photo!');
  hashtag = 'cowboysnation';
  via = 'att';

  constructor() {
    super();
    this.img = sessionStorage.getItem(StorageKey.FinalImage);
  }

  async componentDidMount() {
    this.imageUrl = await SrvM.DataService.getPublicLink();
  }

  public render() {
    const canShare = Boolean(navigator.canShare);
    const buttonText = SrvM.i18n.txt(canShare ? CnstM.StringKey.ShareYourPhoto : CnstM.StringKey.DownloadYourPhoto);
    return (
      <div className="thanks-page-container center-page">
        <span className="thanks-header heading-glow">
          {SrvM.i18n.txt(CnstM.StringKey.Thanks)}
        </span>
        <span className="thanks-tag-social">
          {SrvM.i18n.txt(CnstM.StringKey.TagOnSocial)}
        </span>
        <div className="final-image-container">
          <img className="final-image" src={this.img}></img>
          <button
            className="button1"
            onClick={this.handleSharePhotoButtonClicked}
          >
            {buttonText}
          </button>
        </div>
        <div className="another-photo-container">
          <button
            className="button-small another-photo-button"
            onClick={this.handleTakeAnotherPhotoButtonClicked}
          >
            {SrvM.i18n.txt(CnstM.StringKey.TakeAnotherPhoto)}
          </button>
        </div>
        <div className="link-buttons-container">
          <button
            className="link-button"
            onClick={this.handleShareButtonClicked}
          >
            {SrvM.i18n.txt(CnstM.StringKey.ShareFacebook)}
          </button>
          <button
            className="link-button"
            onClick={this.handleTwitterShareButtonClicked}
          >
            {SrvM.i18n.txt(CnstM.StringKey.ShareTwitter)}
          </button>
        </div>
      </div>
    );
  }

  private handleTakeAnotherPhotoButtonClicked = () => {
    window.location.replace('/');
  };

  private handleDownloadPhotoButtonClicked = async () => {
    SrvM.Logger.track(ModM.GAEvents.Download);
    const blob = await (await fetch(this.img)).blob();
    const blobUrl = URL.createObjectURL(blob);
    this.openUrl(blobUrl, this.fileName);
  };

  private handleSharePhotoButtonClicked = async () => {
    const iOS = SrvM.DeviceService.detectDevice().iOS;
    const blob = await (await fetch(this.img)).blob();
    const freshFileName = `${Branding.downloadFileName
      }_${new Date().getTime()}.jpeg`;

    const file = new File([blob], freshFileName, {
      type: 'image/jpeg',
      lastModified: new Date().getTime(),
    });

    if (navigator.canShare && navigator.canShare({ files: [file] })) {
      SrvM.Logger.track(ModM.GAEvents.ShareDownload);
      navigator
        .share({
          files: [file],
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      await this.handleDownloadPhotoButtonClicked()
    }
  };

  private handleShareButtonClicked = () => {
    SrvM.Logger.track(ModM.GAEvents.Sharing, { event_category: 'facebook' });
    const url = `https://www.facebook.com/sharer.php?u=${this.imageUrl}&t=Thisistitle`;
    this.openUrl(url);
  };

  private handleTwitterShareButtonClicked = () => {
    SrvM.Logger.track(ModM.GAEvents.Sharing, { event_category: 'twitter' });
    const url = `https://x.com/intent/tweet?source=webclient&text=${this.imageUrl}`;
    this.openUrl(url);
  };

  private openUrl(url: string, download?: string) {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    if (download) a.download = download;
    document.body.appendChild(a);
    a.click();
  }
}
