import './camera.scss';

import { Component, Fragment, h } from 'preact';
import { RoutableProps } from 'preact-router';

import LoadingImage from '../../assets/img/loading.png';
import { Constants, StorageKey } from '../constants';
import { Player, PlayerPosition } from '../models';
import { ActM, CnstM, ImgM, SrvM } from '../modules';
import { Routes } from '../routes';
import { PreloadService, Utils } from '../services';

interface IProps extends RoutableProps {
  stream: MediaStream;
  isAllowMotion: boolean;
  players: Player[];
}

interface IState {
  showControls: boolean;
  topText: string;
  countDownLabel: string;
  isEnableCaptureButton: boolean;
  loading: boolean;
}

interface IPhotoData {
  position: PlayerPosition;
  image: HTMLImageElement;
}

export class CameraPage extends Component<IProps, IState> {
  video: HTMLVideoElement;
  loadedPhotos: IPhotoData[] = [];
  photoHeader: HTMLImageElement;

  componentWillMount() {
    this.setState({
      showControls: true,
      topText: SrvM.i18n.txt(CnstM.StringKey.GetReady),
      isEnableCaptureButton: false,
      loading: false,
      countDownLabel: '',
    });
  }

  componentDidMount() {
    this.fixVideoContainer();
    this.video = document.getElementById('cameraVideo') as HTMLVideoElement;
    this.video.srcObject = this.props.stream;
    this.video.addEventListener('loadedmetadata', () => {
      this.dumpStreamInfo(this.props.stream);
    });

    if (
      typeof DeviceOrientationEvent !== 'undefined' &&
      this.props.isAllowMotion
    ) {
      window.addEventListener('deviceorientation', this.handleOrientation);
    }

    setTimeout(() => this.setState({ isEnableCaptureButton: true }), 1000);
    if (PreloadService.CameraPreloadComplete) {
      this.getPreloadedResources();
    } else {
      const promise = PreloadService.CameraPreload;
      this.setState({ loading: true });
      promise.then(() => {
        this.setState({ loading: false });
        this.getPreloadedResources();
      });
    }
  }

  getPreloadedResources() {
    this.loadedPhotos = PreloadService.LoadedPlayerPhotos;
    this.photoHeader = PreloadService.PhotoHeader;
    const videoContainer = document.getElementById('otherVideos');
    const videos = PreloadService.LoadedCameraVideos;
    const zOrder = [
      PlayerPosition.TopLeft,
      PlayerPosition.TopRight,
      PlayerPosition.BottomLeft,
      PlayerPosition.BottomRight,
      PlayerPosition.BottomCenter,
    ];
    zOrder.forEach((position) => {
      const videoId = `video${position}`;
      const video = videos.find((v) => v.id === videoId);
      videoContainer.appendChild(video);
    });
  }

  fixVideoContainer() {
    const width = document.documentElement.clientWidth;
    const height = document.documentElement.clientHeight;
    SrvM.Logger.log('screen', `${height}x${width}`);
    const container = document.getElementById(
      'camera-container'
    ) as HTMLDivElement;
    let newHeight,
      newWidth = 0;
    if (height / width <= 16 / 9) {
      newWidth = height * 0.565;
      newHeight = height;
    } else {
      newWidth = width;
      newHeight = width * 1.77;
    }
    container.setAttribute(
      'style',
      `width: ${newWidth}px; height: ${newHeight}px`
    );
  }

  dumpStreamInfo(stream: MediaStream) {
    const videoTrack = stream.getVideoTracks()[0];
    SrvM.Logger.log(
      `Stream: ${videoTrack.label} with settings ${JSON.stringify(
        videoTrack.getSettings(),
        null,
        2
      )}`
    );
    if (videoTrack.getCapabilities) {
      const caps = videoTrack.getCapabilities();
      SrvM.Logger.log(
        `Supported capabilities are ${JSON.stringify(caps, null, 2)}`
      );
    }
  }

  componentWillUnmount() {
    if (this.props.stream) {
      SrvM.Logger.log('Getting rid of video stream and camera access');
      this.props.stream.getTracks().forEach((track) => {
        track.stop();
        SrvM.Logger.log(`Stream track ${track.kind} ${track.id} stopped`, track);
      });
      if (this.video) {
        this.video.srcObject = null;
        this.video = null;
      }
      this.props.stream = null;
    }
  }

  async showPlayers(): Promise<any> {
    return new Promise((resolve, reject) => {
      let readyPlayerCount = 0;
      const videoElements = this.props.players.map(
        (player) =>
          document.getElementById(`video${player.position}`) as HTMLVideoElement
      );
      videoElements.forEach((videoPlayer) => {
        videoPlayer.classList.add('video-playing');
        const checkVideoTime = () => {
          if (videoPlayer.currentTime >= CnstM.Constants.PauseAtTimeSeconds) {
            videoPlayer.pause();
            readyPlayerCount++;
            if (readyPlayerCount === this.props.players.length) {
              resolve(null);
            }
          } else {
            requestAnimationFrame(checkVideoTime);
          }
        };
        requestAnimationFrame(checkVideoTime);
        videoPlayer.play().catch((err) => {
          SrvM.Logger.error('showPlayers', err);
          reject(err);
        });
      });
    });
  }

  async hidePlayers() {
    return new Promise((resolve) => {
      let readyPlayerCount = 0;
      const videoElements = this.props.players.map(
        (player) =>
          document.getElementById(`video${player.position}`) as HTMLVideoElement
      );
      videoElements.forEach((playerVideo) => {
        playerVideo.onended = () => {
          readyPlayerCount++;
          if (readyPlayerCount === this.props.players.length) {
            resolve(null);
          }
        };
        playerVideo.play();
      });
    });
  }

  async captureAndSavePhoto() {
    await this.flash();
    const hiddenCanvas = document.createElement('canvas') as HTMLCanvasElement;
    hiddenCanvas.width = Constants.CameraHeight; /* yes */
    hiddenCanvas.height = Constants.CameraWidth;
    const hctx = hiddenCanvas.getContext('2d');
    const vw = this.video.videoWidth;
    const vh = this.video.videoHeight;
    const needsScale = vw < hiddenCanvas.width;
    const scale = needsScale
      ? Math.min(hiddenCanvas.width / vw, hiddenCanvas.height / vh)
      : 1.0;
    const orX = needsScale ? hiddenCanvas.width / 2 - (vw / 2) * scale : 0;
    const orY = needsScale ? hiddenCanvas.height / 2 - (vh / 2) * scale : 0;
    hctx.drawImage(this.video, orX, orY, vw * scale, vh * scale);
    this.drawPlayers(hctx);
    this.drawPhotoHeader(hctx);

    const croppedCanvas = this.cropImage(
      hctx,
      Constants.CropHeight,
      Constants.CropBottomMargin
    );
    const localImage = croppedCanvas.toDataURL(
      'image/jpeg',
      Constants.PhotoCompressQuality
    );
    sessionStorage.setItem(StorageKey.FinalImage, localImage);
    await SrvM.DataService.getUploadUrls();
    SrvM.DataService.uploadUserPhoto(localImage)
      .then(() => {
        SrvM.Logger.log(`Camera: img upload complete`);
      })
      .catch((e) => {
        SrvM.Logger.error(`Camera: img upload failed`, e);
      });
  }

  cropImage(
    ctx: CanvasRenderingContext2D,
    cropHeight: number,
    bottomMargin: number
  ) {
    const imageData = ctx.getImageData(
      0,
      Constants.CanvasHeight - cropHeight - bottomMargin,
      Constants.CanvasWidth,
      cropHeight
    );
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = Constants.CanvasWidth;
    tempCanvas.height = cropHeight;
    const tempContext = tempCanvas.getContext('2d');
    tempContext.putImageData(imageData, 0, 0);
    return tempCanvas;
  }

  async flash() {
    const flashTime = CnstM.Constants.FlashTimeMs / 2;
    const flash = document.getElementById('flash');
    flash.style.opacity = '1';
    await Utils.delay(flashTime);
    flash.style.opacity = '0';
    await Utils.delay(flashTime);
  }

  drawPlayers(ctx: CanvasRenderingContext2D) {
    this.drawPlayerPosition(ctx, PlayerPosition.TopLeft);
    this.drawPlayerPosition(ctx, PlayerPosition.TopRight);
    this.drawPlayerPosition(ctx, PlayerPosition.BottomLeft);
    this.drawPlayerPosition(ctx, PlayerPosition.BottomRight);
    this.drawPlayerPosition(ctx, PlayerPosition.BottomCenter);
  }

  drawPlayerPosition(ctx: CanvasRenderingContext2D, position: PlayerPosition) {
    const playerPhotoIndex = this.loadedPhotos.findIndex(
      (p) => p.position === position
    );
    if (playerPhotoIndex > -1) {
      const playerPhoto = this.loadedPhotos[playerPhotoIndex];
      ctx.drawImage(playerPhoto.image, 0, 0);
    }
  }

  drawPhotoHeader(ctx: CanvasRenderingContext2D) {
    ctx.drawImage(
      this.photoHeader,
      0,
      Constants.CanvasHeight - Constants.CropHeight - Constants.CropBottomMargin
    );
  }

  async runCountdownFrom(seconds: number) {
    while (seconds > 0) {
      const text = seconds.toString();
      this.setState({ countDownLabel: text });
      await Utils.delay(1000);
      seconds -= 1;
    }
    this.setState({ countDownLabel: '' });
  }

  public render() {
    return (
      <div id="camera-container" class="camera-container">
        {this.state.showControls &&
        !this.state.loading &&
        this.props.isAllowMotion ? (
          <Fragment>
            <div class="gyroscope-container">
              <div class="gyroscope-center"></div>
              <div class="gyroscope-ball"></div>
            </div>
            <div class="level-phone-container">
              <img class="left-vector" src={ImgM.LeftVector} />
              <div class="left-vector-title">
                {SrvM.i18n.txt(CnstM.StringKey.LevelPhone)}
                <br />
                {SrvM.i18n.txt(CnstM.StringKey.LevelPhoneToBegin)}
              </div>
            </div>
          </Fragment>
        ) : null}
        <video
          id="cameraVideo"
          className="video"
          muted
          autoPlay
          playsInline
        ></video>
        <div id="otherVideos" className="video"></div>
        <canvas
          id="cameraFrameCanvas"
          width={Constants.CanvasWidth}
          height={Constants.CanvasHeight}
          className="photo-canvas"
        />
        <div className="top-container">
          <div>
            <h1 className="page-title-text title-glow">{this.state.topText}</h1>
            <span className="countdown-label title-glow">
              {this.state.countDownLabel}
            </span>
          </div>

          {this.state.showControls ? (
            <div className="bottom-container">
              {!this.state.loading ? (
                <Fragment>
                  <div className="stand-here-container text-glow-black">
                    <p>Stand here</p>
                    <img className="place-img" src={ImgM.StandHere} />
                  </div>
                  <img className="place-img" src={ImgM.PlaceCircle} />
                  <button
                    className="button1"
                    disabled={!this.state.isEnableCaptureButton}
                    onClick={this.handleCapturePhotoButtonClicked}
                  >
                    {SrvM.i18n.txt(CnstM.StringKey.CapturePhoto)}
                  </button>
                </Fragment>
              ) : null}
              <div className="bottom-buttons">
                <button
                  className="back-button"
                  onClick={this.handleBackButtonClicked}
                >
                  {SrvM.i18n.txt(CnstM.StringKey.Back)}
                </button>
              </div>
            </div>
          ) : null}
        </div>
        {this.state.loading ? (
          <div className="top-container loading-view">
            <h2>&nbsp;</h2>
            <img src={LoadingImage} className="rotating loading-item" />
            <h2>Loading experience</h2>
          </div>
        ) : null}
        <div id="flash" className="video flash-view" />
      </div>
    );
  }

  private handleCapturePhotoButtonClicked = async () => {
    this.setState({ showControls: false, topText: '' });

    if (
      typeof DeviceOrientationEvent !== 'undefined' &&
      this.props.isAllowMotion
    ) {
      window.removeEventListener('deviceorientation', this.handleOrientation);
    }

    await this.showPlayers();
    await this.runCountdownFrom(3);
    await this.captureAndSavePhoto();
    this.hidePlayers();
    await Utils.delay(Constants.DelayBeforeLeaveCameraScreen);
    ActM.AppActions.route(Routes.UserForm);
  };

  private handleBackButtonClicked = () => {
    ActM.AppActions.route(Routes.CameraSelect);
  };

  private handleOrientation = (event: DeviceOrientationEvent) => {
    const percent = (event.beta * 100) / 180;
    if (percent > 45 && percent < 55 && !this.state.isEnableCaptureButton) {
      this.setState({ isEnableCaptureButton: true });
      document.documentElement.style.setProperty('--level-visible', 'hidden');
    } else if (
      (percent < 45 || percent > 55) &&
      this.state.isEnableCaptureButton
    ) {
      this.setState({ isEnableCaptureButton: false });
      document.documentElement.style.setProperty('--level-visible', 'visible');
    }

    if (percent > 6 && percent < 94) {
      document.documentElement.style.setProperty(
        '--ball-position',
        `${percent}%`
      );
    }
  };
}
