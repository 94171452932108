export class Routes {
  public static Help = '/help';
  public static Welcome = '/welcome';
  public static UserForm = '/userform';
  public static Players = '/players';
  public static LookAtScreen = '/lookatscreen';
  public static Thanks = '/thanks';
  public static Submit = '/submit';
  public static Camera = '/camera';
  public static CameraSelect = '/cameraselect';
  public static Instructions = '/instructions';
  public static Config = '/config';
}
