import { route } from 'preact-router';

import { Language, StorageKey } from '../constants';
import { Constants } from '../constants';
import { CnstM, ModM } from '../modules';
import { Routes } from '../routes';
import * as SrvM from '../services';

const routeTo = (path: string) => {
  document.documentElement.style.setProperty('--page-opacity', '0');
  setTimeout(() => {
    route(path, true);
    document.documentElement.style.setProperty('--page-opacity', '1');
    SrvM.Logger.track('page_view', { page_path: path });
  }, 300);
};

export const AppActions = {
  init: (onSuccess: () => void) => {
    sessionStorage.clear();
    SrvM.i18n.setLang(Language.EN);
    const deviceInfo = SrvM.DeviceService.detectDevice();
    const initialRoute = location.hash.includes(Routes.Config)
      ? Routes.Config
      : Routes.Welcome;
    routeTo(initialRoute);
    const shouldCheckLocation = deviceInfo.isMobile && !deviceInfo.isWebView;
    if (shouldCheckLocation) {
      checkLocationAndGo(onSuccess);
    }
  },
  showFullscreenMessage: (message: string) => {
    showFullscreenMessage(message);
  },
  destroy: () => {
    sessionStorage.clear();
  },
  route: routeTo,
};

const showFullscreenMessage = (message: string, isLong?: boolean) => {
  SrvM.Logger.log('showFullscreenMessage', message);
  document.documentElement.style.setProperty('--desktop-alert', 'visible');
  document.body.classList.add('desktop-alert-on');
  if (isLong) {
    document.body.classList.add('desktop-alert-long');
  }
  const elements = document.getElementsByClassName('desktop-alert-title');
  if (elements) {
    const element = elements[0];
    element.textContent = message;
  }
};

const showLoading = (visible: boolean) => {
  document.documentElement.style.setProperty(
    '--desktop-loading',
    visible ? 'visible' : 'hidden'
  );
};

const getPreviousCheckValue = () => {
  return localStorage.getItem(StorageKey.LocationCheckCache) || '';
};

const setPreviousCheckValue = (val: string) => {
  localStorage.setItem(StorageKey.LocationCheckCache, val);
};

const checkLocationAndGo = (onSuccess: () => void) => {
  if (CnstM.Constants.IsGeoCheckEnabled) {
    const checkCache = getPreviousCheckValue();
    if (checkCache) {
      if (checkCache === ModM.GeoCheckResult.Outside) {
        SrvM.Logger.track(ModM.GAEvents.GeoCheck, {
          event_category: 'cached-outside',
        });
        showFullscreenMessage(
          SrvM.i18n.txt(CnstM.StringKey.YouAreOutside),
          true
        );
        return;
      } else if (checkCache === ModM.GeoCheckResult.Inside) {
        SrvM.Logger.track(ModM.GAEvents.GeoCheck, {
          event_category: 'cached-inside',
        });
        if (onSuccess) {
          onSuccess();
        }
        return;
      }
    }

    showLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (p) => {
          showLoading(false);
          const distance = getDistanceInKm(
            p.coords.latitude,
            p.coords.longitude,
            Constants.ATTStaduimLocation.lat,
            Constants.ATTStaduimLocation.lon
          );
          if (distance > Constants.DistanceToStadiumKm) {
            showFullscreenMessage(
              SrvM.i18n.txt(CnstM.StringKey.YouAreOutside),
              true
            );
            setPreviousCheckValue(ModM.GeoCheckResult.Outside);
            SrvM.Logger.track(ModM.GAEvents.GeoCheck, {
              event_category: 'outside',
            });
          } else {
            setPreviousCheckValue(ModM.GeoCheckResult.Inside);
            SrvM.Logger.track(ModM.GAEvents.GeoCheck, {
              event_category: 'inside',
            });
            if (onSuccess) {
              onSuccess();
            }
          }
        },
        (error) => {
          SrvM.Logger.error('getCurrentPosition', error);
          if (error.message) {
            SrvM.Logger.error(error.message);
          }
          showLoading(false);
          showFullscreenMessage(SrvM.i18n.txt(CnstM.StringKey.TurnOnGPS), true);
        },
        {}
      );
    } else {
      showLoading(false);
      showFullscreenMessage(SrvM.i18n.txt(CnstM.StringKey.TurnOnGPS), true);
    }
  } else {
    if (onSuccess) {
      onSuccess();
    }
  }
};

const getDistanceInKm = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const R = 6371; // km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const lat1Rad = toRad(lat1);
  const lat2Rad = toRad(lat2);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) *
      Math.sin(dLon / 2) *
      Math.cos(lat1Rad) *
      Math.cos(lat2Rad);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
};

const toRad = (value: number) => {
  return (value * Math.PI) / 180;
};
