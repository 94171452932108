import './help.scss';

import { Component, h } from 'preact';
import { RoutableProps } from 'preact-router';

import { LangSwitch } from '../components';
import { ActM, CnstM, ImgM, SrvM } from '../modules';
import { Routes } from '../routes';

const steps: IStep[] = [
  {
    number: 1,
    strKey: CnstM.StringKey.EnterInfo,
    imgSrc: ImgM.Steps[0],
  },
  {
    number: 2,
    strKey: CnstM.StringKey.HoldQR,
    imgSrc: ImgM.Steps[1],
  },
  {
    number: 3,
    strKey: CnstM.StringKey.ControlScreen,
    imgSrc: ImgM.Steps[2],
  },
];

interface IStep {
  number: number;
  strKey: CnstM.StringKey;
  imgSrc: any;
}

const Step = (props: IStep) => {
  return (
    <div className="steps-item">
      <div className="steps-item-text-container">
        <div className="steps-item-text">
          <span className="steps-item-header">
            {SrvM.i18n.txt(CnstM.StringKey.Step)} {props.number}
          </span>
          <span className="steps-item-info">{SrvM.i18n.txt(props.strKey)}</span>
        </div>
        <img
          className="steps-image"
          src={props.imgSrc}
          alt={`${SrvM.i18n.txt(CnstM.StringKey.Step)} ${props.number}`}
        />
      </div>
    </div>
  );
};

interface IState {
  lang: CnstM.Language;
}

export class HelpPage extends Component<RoutableProps, IState> {
  state = {
    lang: SrvM.i18n.getLang(),
  };

  public componentWillMount() {
    this.setState({ lang: SrvM.i18n.getLang() });
  }

  public render() {
    return (
      <section class="page-help">
        <h1 className="page-title text-glow">
          {SrvM.i18n.txt(CnstM.StringKey.GetStarted)}
        </h1>
        <div className="steps-block">
          {steps.map((props) => (
            <Step key={props.number} {...props} />
          ))}
        </div>
        <button className="button1" onClick={this.handleContinueClicked}>
          {SrvM.i18n.txt(CnstM.StringKey.Continue)}
        </button>
        <LangSwitch onSwitch={this.handleLangSwitch} />
      </section>
    );
  }

  // handlers

  private handleContinueClicked = () => {
    ActM.AppActions.route(Routes.Welcome);
  };

  private handleLangSwitch = (lang: CnstM.Language) => {
    this.setState({ lang });
  };
}
