import { Logger } from './logger';
import { IUrlsData } from '../models';

export class DataService {
  private static currentUserContext: IUrlsData;

  public static async getUploadUrls(): Promise<IUrlsData> {
    try {
      const result = await fetch('/get-upload-url', { method: 'GET' }).then(
        (response) => response.json()
      );
      Logger.log(result);
      DataService.currentUserContext = result;
      return result;
    } catch (e) {
      Logger.error(`Failed to get upload urls`);
    }
  }

  public static async uploadUserPhoto(imageData: string) {
    try {
      const blob = await (await fetch(imageData)).blob();
      const file = new File([blob], this.currentUserContext.imgName, {
        type: blob.type,
      });
      await this.presignedPostFile(file, this.currentUserContext.imgUploadData);
      Logger.log(`Image ${this.currentUserContext.imgName} was uploaded`);
    } catch (e) {
      Logger.error(`Image upload failed`);
    }
  }

  public static async uploadUserFormData(
    email: string,
    optin: boolean,
    tosAgreed: boolean
  ) {
    const json = {
      customformdata: {
        originalid: this.currentUserContext.key,
      },
      emailaddress: email,
      imagename: this.currentUserContext.imgName,
      videoname: '',
      phonenumber: null,
      optin: optin,
      tosagreed: tosAgreed,
      sharevia: 'Email',
    };
    try {
      const data = DataService.simpleJsonEncode(JSON.stringify(json, null, 2));
      const blob = new Blob([data], { type: 'application/json' });
      const file = new File([blob], this.currentUserContext.jsonName, {
        type: blob.type,
      });
      await this.presignedPostFile(file, this.currentUserContext.jsonPostData);
      Logger.log(`Form data ${this.currentUserContext.jsonName} was saved`);
    } catch (e) {
      Logger.error(`Form data save failed`);
    }
  }

  public static async getPublicLink() {
    // use this for sharing
    return `https://posewiththepros.net/5/${this.currentUserContext.key}`;
  }

  private static simpleJsonEncode(text: string) {
    const out = [];
    for (let i = 0; i < text.length; i++) {
      out[i] = text.charCodeAt(i);
    }
    return new Uint8Array(out);
  }

  private static presignedPostFile(
    file: File,
    presignedPostData
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(presignedPostData.fields).forEach((key) => {
        formData.append(key, presignedPostData.fields[key]);
      });
      // Actual file has to be appended last.
      formData.append('file', file);
      const xhr = new XMLHttpRequest();
      xhr.open('POST', presignedPostData.url, true);
      xhr.send(formData);
      xhr.onload = function () {
        this.status === 204 ? resolve(null) : reject(this.responseText);
      };
    });
  }
}
