import detect from 'detect.js';
import isWebview from 'is-ua-webview';
import isMobile from 'ismobilejs';

import { ActM, CnstM, SrvM } from '../modules';

export class DeviceService {
  private static isDetected = false;
  private static deviceInfo: IDeviceInfo = null;

  public static detectDevice() {
    if (DeviceService.isDetected) {
      return this.deviceInfo;
    }
    const nav = navigator as any;
    const ua = detect.parse(navigator.userAgent);
    const isWebView =
      isWebview(navigator.userAgent) || this.isIosWebViewExperimental();

    const isMobileDevice = isMobile(navigator.userAgent).any;
    if (!isMobileDevice) {
      ActM.AppActions.showFullscreenMessage(
        SrvM.i18n.txt(CnstM.StringKey.DesktopAlert)
      );
    }
    const iOS = ua.os.family === 'iOS';
    const android = ua.os.family === 'Android';
    const osVersionMajor = ua.os.major;

    const category = DeviceService.detectMobileDeviceType(
      iOS,
      android,
      osVersionMajor
    );
    const iOS14 = iOS && parseFloat(ua.os.major) >= 14.0;

    this.deviceInfo = {
      isWebView: isWebView || (iOS && ua.browser.family !== 'Mobile Safari'),
      isMobile: isMobileDevice,
      android: android,
      iOS: iOS,
      iOS14: iOS14,
      webP: android || iOS14,
      deviceCategory: category,
      deviceDetails: ua,
      pixelRatio: window.devicePixelRatio || 0,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      iPad:
        !!navigator.userAgent.match(/(iPad)/) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1),
      RAM: android && nav.deviceMemory ? nav.deviceMemory : 0,
      cores: navigator.hardwareConcurrency || 0,
      net: nav.connection
        ? `t: ${nav.connection.type} | et: ${nav.connection.effectiveType} | ds: ${nav.connection.saveData}`
        : '',
    };
    SrvM.Logger.log(
      `DeviceInfo: ${JSON.stringify(this.deviceInfo)}\r\n UA: ${
        navigator.userAgent
      }`
    );
    DeviceService.isDetected = true;
    return this.deviceInfo;
  }

  public static detectMobileDeviceType = (
    iOS: boolean,
    isAndroid: boolean,
    osVersionMajor: number
  ) => {
    const isAndroid10 = isAndroid && osVersionMajor >= 10.0;
    const screenHeight = window.screen.height;
    const screenWidth = window.screen.width;
    const cores = navigator.hardwareConcurrency || 0;
    const nav = navigator as any;
    const RAM = isAndroid && nav.deviceMemory ? nav.deviceMemory : 0;
    if (iOS) {
      if (screenWidth === 414 && screenHeight === 896) {
        return MobileDeviceType.NewIOS; // "XMax-XR";
      } else if (screenWidth === 375 && screenHeight === 812) {
        return MobileDeviceType.NewIOS; // "X-XS";
      }
      return MobileDeviceType.OldIOS;
    } else {
      if (isAndroid) {
        /* verify this check on new Android phones */
        if (isAndroid10 && cores >= 8 && RAM > 4) {
          return MobileDeviceType.NewAndroid;
        } else {
          return MobileDeviceType.OldAndroid;
        }
      }
    }
    // default
    return MobileDeviceType.OldAndroid;
  };

  private static isIosWebViewExperimental() {
    const html = document.getElementById('phone').innerHTML;
    if (navigator.platform.substr(0, 2) === 'iP') {
      if (html.indexOf('tel:') == -1) {
        return true;
      }
    }
    return false;
  }
}

export interface IDeviceInfo {
  isWebView: boolean;
  isMobile: boolean;
  android: boolean;
  iOS: boolean;
  iOS14: boolean;
  iPad: boolean;
  webP: boolean;
  deviceCategory: MobileDeviceType;
  deviceDetails: any;
  pixelRatio: number;
  screenWidth: number;
  screenHeight: number;
  RAM: number;
  cores: number;
  net: string;
}

export enum MobileDeviceType {
  OldIOS = 'OldIOS',
  NewIOS = 'NewIOS',
  OldAndroid = 'OldAndroid',
  NewAndroid = 'NewAndroid',
}
