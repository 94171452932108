import { createHashHistory } from 'history';
import { Component, Fragment, h } from 'preact';
import { Router } from 'preact-router';

import { Footer, Header } from './components';
import { Player } from './models';
import { ActM, CnstM, SrvM } from './modules';
import * as Pages from './pages';
import { Routes } from './routes';
import { PreloadService } from './services';

const history = createHashHistory();

interface IState {
  fullscreen: boolean;
  stream: MediaStream;
  isAllowMotion: boolean;
  selectedPlayers: Player[];
  isLoading: boolean;
}

export class App extends Component<any, IState> {
  private oHeight: number;
  private oWidth: number;

  state = {
    fullscreen: false,
    stream: null,
    isAllowMotion: false,
    selectedPlayers: [],
    isLoading: false,
  };

  public componentDidMount() {
    this.setState({ isLoading: true });
    const device = SrvM.DeviceService.detectDevice();
    this.oHeight = window.innerHeight;
    this.oWidth = window.innerWidth;
    this.addEventListeners();
    this.updateViewPortHeight();
    ActM.AppActions.init(() => {
      if (!device.isWebView) {
        PreloadService.startPreloadForMenu(() => {
          this.setState({ isLoading: false });
        });
      }
    });
  }

  public componentWillUnmount() {
    this.handleUnmount();
    this.removeEventListeners();
  }

  public render() {
    return (
      <Fragment>
        {!this.state.fullscreen ? <Header /> : null}
        <main class="align-cc">
          <Router history={history} onChange={this.handleRouteChanged}>
            <Pages.HelpPage path={Routes.Help} />
            <Pages.WelcomePage path={Routes.Welcome} default />
            <Pages.UserFormPage path={Routes.UserForm} />
            <Pages.PlayersPage
              onSelectPlayers={this.handleSelectPlayers}
              isLoading={this.state.isLoading}
              path={Routes.Players}
            />
            <Pages.ThanksPage path={Routes.Thanks} />
            <Pages.SubmitPage path={Routes.Submit} />
            <Pages.CameraSelectPage
              path={Routes.CameraSelect}
              onStreamChanged={this.handleStreamChanged}
              onAllowMotion={this.handleAllowMotion}
            />
            <Pages.CameraPage
              stream={this.state.stream}
              players={this.state.selectedPlayers}
              isAllowMotion={this.state.isAllowMotion}
              path={Routes.Camera}
            />
            <Pages.InstructionsPage path={Routes.Instructions} />
            <Pages.ConfigPage path={Routes.Config} />
          </Router>
        </main>
        <div id="playersVideos" className="player-video-container" />
        <div class="landscape-alert">
          <div class="landscape-container">
            <div class="alert-title">
              {SrvM.i18n.txt(CnstM.StringKey.RotateTitle)}
            </div>
            <div class="alert-sub-title">
              {SrvM.i18n.txt(CnstM.StringKey.RotateSubtitle)}
            </div>
          </div>
        </div>
        {!this.state.fullscreen ? <Footer /> : null}
      </Fragment>
    );
  }

  private handleRouteChanged = (route) => {
    this.setState({ fullscreen: route.url === Routes.Camera });
  };

  private handleAllowMotion = (isAllow: boolean) => {
    this.setState({ isAllowMotion: isAllow });
  };

  private handleSelectPlayers = (players: Player[]) => {
    this.setState({ selectedPlayers: players });
  };

  private handleUnmount = () => {
    ActM.AppActions.destroy();
  };

  private addEventListeners = () => {
    document.addEventListener('touchstart', () => null, false);
    window.addEventListener('resize', this.updateViewPortHeight);
    window.addEventListener('beforeunload', this.handleUnmount);
  };

  private removeEventListeners = () => {
    document.removeEventListener('touchstart', () => null);
    window.removeEventListener('resize', this.updateViewPortHeight);
    window.removeEventListener('beforeunload', this.handleUnmount);
  };

  private updateViewPortHeight = () => {
    const vh = window.innerHeight * 0.01;
    if (this.oWidth !== window.innerWidth) {
      this.oHeight = window.innerHeight;
      this.oWidth = window.innerWidth;
    }
    const fontSizeVh = this.oHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty(
      '--font-size',
      `${fontSizeVh}px`
    );
  };

  private handleStreamChanged = (stream: MediaStream) => {
    this.setState({ stream });
  };
}
