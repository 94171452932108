import './config.scss';

import { Component, h } from 'preact';

import { StorageKey } from '../constants';
import {
  ConfigStore,
  PlayerConfigStore,
  PlayerVideoConfigStore,
} from '../models/config';
import { Branding, ImgM } from '../modules';

interface IState {
  selectedPlayerId: number;
  config: ConfigStore;
  testVideoUrl?: string;
}

export class ConfigPage extends Component<any, IState> {
  constructor() {
    super();

    const configValue = localStorage.getItem(StorageKey.Config);

    this.state = {
      selectedPlayerId: Branding.team.players[0].id,
      config: new ConfigStore(JSON.parse(configValue)),
    };
  }

  public componentWillMount() {
    document.body.classList.add('config-mode');
  }

  public componentWillUnmount() {
    document.body.classList.remove('config-mode');
  }

  public componentWillReceiveProps() {
    const configValue = localStorage.getItem(StorageKey.Config);

    this.setState({
      config: new ConfigStore(JSON.parse(configValue)),
    });
  }

  // renders

  public render() {
    const player = Branding.team.players.find(
      (p) => p.id === this.state.selectedPlayerId
    );
    const configPlayer =
      !!this.state.config &&
      !!this.state.config.players &&
      this.state.config.players.find(
        (p) => p.id === this.state.selectedPlayerId
      );

    return (
      <div className="config-page">
        <div className="player-img-wrapper align-cc">
          <img
            className="player-img"
            src={ImgM.Players[player.id - 1]}
            alt={`${player.firstName} ${player.lastName} photo.`}
          />
        </div>

        <form onSubmit={this.handleSave}>
          {this.renderPlayerSelector()}
          {this.renderVideosConfig(
            Branding.video.parts,
            'Select Screen Videos',
            configPlayer
          )}
          {this.renderVideosConfig(
            Branding.team.positions,
            'Camera Screen Videos',
            configPlayer
          )}

          <button className="button1" type="submit">
            Save
          </button>
        </form>

        {!!this.state.testVideoUrl && (
          <video
            src={this.state.testVideoUrl}
            autoPlay
            muted
            playsInline
            onEnded={this.handleVideoEnded}
          ></video>
        )}
      </div>
    );
  }

  private renderPlayerSelector() {
    return (
      <fieldset>
        <legend>Player</legend>

        <select onChange={this.handlePlayerChange}>
          {Branding.team.players.map((p) => {
            return (
              <option
                value={p.id}
                selected={p.id === this.state.selectedPlayerId}
              >
                {p.id}. {p.firstName} {p.lastName}
              </option>
            );
          })}
        </select>
      </fieldset>
    );
  }

  private renderVideosConfig(
    keys: string[],
    title: string,
    configPlayer?: PlayerConfigStore
  ) {
    return (
      <fieldset>
        <legend>{title}</legend>

        {keys.map((key) => {
          const video =
            !!configPlayer &&
            !!configPlayer.videos &&
            configPlayer.videos.find((v) => v.key === key);
          const value = video ? video.url : '';
          return (
            <label>
              <span>{key}</span>
              <input
                type="text"
                placeholder="Input URL to video file here."
                value={value}
                onChange={(e) => this.handleUrlChange(e, key)}
              />
              <button
                disabled={!value}
                type="button"
                onClick={() => this.handleTestVideoUrl(value)}
              >
                Test
              </button>
            </label>
          );
        })}
      </fieldset>
    );
  }

  // handlers

  private handlePlayerChange = (e: any) => {
    this.setState({ selectedPlayerId: Number(e.target.value) });
  };

  private handleTestVideoUrl = async (testVideoUrl: string) => {
    try {
      const res = await fetch(testVideoUrl);
      if (!res.ok) throw new Error(`${res.status} ${res.statusText}`);
      this.setState({ testVideoUrl });
    } catch (err) {
      alert(err.message);
    }
  };

  private handleUrlChange = (e: any, key: string) => {
    const url = e.target.value;
    const config = { ...this.state.config };
    const player = config.players.find(
      (p) => p.id === this.state.selectedPlayerId
    );
    if (!player) {
      config.players.push(
        new PlayerConfigStore({
          id: this.state.selectedPlayerId,
          videos: [new PlayerVideoConfigStore({ key, url })],
        })
      );
    } else {
      const video = player.videos.find((v) => v.key === key);
      if (!video) {
        player.videos.push(new PlayerVideoConfigStore({ key, url }));
      } else {
        video.url = url;
      }
    }

    this.setState({ config });
  };

  private handleSave = (e: any) => {
    if (e.preventDefault) e.preventDefault();
    localStorage.setItem(StorageKey.Config, JSON.stringify(this.state.config));
  };

  private handleVideoEnded = () => {
    this.setState({ testVideoUrl: null });
  };
}
