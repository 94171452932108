import './footer.scss';

import { h } from 'preact';

import { CnstM, SrvM } from '../modules';

export const Footer = () => {
  return (
    <footer class="footer align-cc">
      <img
        className="footer-image"
        src={SrvM.i18n.img(CnstM.ImageKey.Footer)}
      />
    </footer>
  );
};
