import './langSwitch.scss';

import { h } from 'preact';
import { useState } from 'preact/hooks';

import { CnstM, SrvM } from '../modules';

interface IProps {
  onSwitch: (lang: CnstM.Language) => void;
}

export const LangSwitch = (props: IProps) => {
  const [lang, setLang] = useState(SrvM.i18n.getLang());
  const anotherLang =
    lang === CnstM.Language.EN ? CnstM.Language.ES : CnstM.Language.EN;
  const anotherLangString = SrvM.i18n.txt(
    anotherLang === CnstM.Language.ES
      ? CnstM.StringKey.Spanish
      : CnstM.StringKey.English
  );

  const handleSwitchLang = () => {
    SrvM.i18n.setLang(anotherLang);
    setLang(anotherLang);
    props.onSwitch(anotherLang);
  };

  return (
    <button class="lang-switch" type="button" hidden onClick={null}>
      {anotherLangString}
    </button>
  );
};
