import './instructions.scss';

import { Component, h } from 'preact';
import { RoutableProps } from 'preact-router';

import { ActM, CnstM, ImgM, SrvM } from '../modules';
import { Routes } from '../routes';

export class InstructionsPage extends Component<RoutableProps> {
  public render() {
    return (
      <div className="instruction-container center-page">
        <div class="instruction-text select-title-glow">
          {SrvM.i18n.txt(CnstM.StringKey.Instructions)}
        </div>
        <img class="position-img" src={ImgM.DevicePosition} />
        <button className="button1" onClick={() => this.handleContinueButton()}>
          {SrvM.i18n.txt(CnstM.StringKey.Continue)}
        </button>
      </div>
    );
  }

  private handleContinueButton = () => {
    ActM.AppActions.route(Routes.Camera);
  };
}
