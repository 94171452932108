import { Player, PlayerPosition } from '../models';

const bottomCenterPosition = 2;
const topLeftPosition = 4;

export class PlayerManagerService {
  public static setPositions(players: Player[]): Player[] {
    const zachIndex = players.findIndex((p) => p.name === 'Zack');
    if (zachIndex === 3) {
      players = this.changeZackPosition(players);
    }
    const tonyIndex = players.findIndex((p) => p.name === 'Tony');
    if (tonyIndex === topLeftPosition) {
      players = this.changeTonyPosition(players);
    }

    players.forEach((p, index) => {
      switch (index) {
        case 0:
          p.position = PlayerPosition.TopRight;
          break;
        case 1:
          p.position = PlayerPosition.BottomRight;
          break;
        case bottomCenterPosition:
          p.position = PlayerPosition.BottomCenter;
          break;
        case 3:
          p.position = PlayerPosition.BottomLeft;
          break;
        case topLeftPosition:
          p.position = PlayerPosition.TopLeft;
          break;
      }
    });
    return players;
  }

  private static changeZackPosition(players: Player[]): Player[] {
    const firstPlayer = players[0];
    const secondPlayer = players[1];
    const thirdPlayer = players[2];
    const zackPlayer = players[3];
    const fifthPlayer = players[4];
    const newPlayersList = [
      zackPlayer,
      secondPlayer,
      thirdPlayer,
      firstPlayer,
      fifthPlayer,
    ];
    return newPlayersList;
  }

  private static changeTonyPosition(players: Player[]): Player[] {
    const firstPlayer = players[0];
    const secondPlayer = players[1];
    const thirdPlayer = players[2];
    const fourthPlayer = players[3];
    const tonyPlayer = players[topLeftPosition];
    const newPlayersList = [
      firstPlayer,
      tonyPlayer,
      thirdPlayer,
      fourthPlayer,
      secondPlayer,
    ];
    return newPlayersList;
  }
}
